import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const DataNumCard = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5h8a3 3 0 013 3v8a3 3 0 01-3 3H8a3 3 0 01-3-3V8a3 3 0 013-3zM4 8a4 4 0 014-4h8a4 4 0 014 4v8a4 4 0 01-4 4H8a4 4 0 01-4-4V8zm7.204 5.638h1.038l-.387 2.112h.984l.387-2.112h1.365v-.886h-1.204l.269-1.463H15v-.896h-1.177l.392-2.143h-.984l-.392 2.143h-1.043l.392-2.143h-.978l-.393 2.143H9.403v.896h1.247l-.268 1.463H9v.886h1.22l-.387 2.112h.984l.387-2.112zm1.2-.886h-1.038l.263-1.463h1.043l-.269 1.463z"
        fill={color}
      />
    </svg>
  );
});

DataNumCard.defaultProps = {
  color: 'currentColor',
};

DataNumCard.displayName = 'DataNumCard';

export default DataNumCard;
